import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  }
});

function PortfolioPage() {
  const classes = useStyles();
  const { push } = useHistory();

  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
      marginTop: "30"
    }
  };
  return (
    <div className="portfolio-wrapper">
      <Grid container spacing={6} justify="space-evenly">
        <Grid className="foster-together" item xs={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="image"
                className="foster-image"
                alt="Foster Togethe Website"
                height="140"
                image={require("../assets/images/foster-together-project.png")}
                title="Foster Togethe Website"
                style={styles.media}
                onClick={() => {
                  window.open(
                    "https://github.com/Lambda-School-Labs/foster-together-fe"
                  );
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Foster Together
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Foster Together is a non-profit organization that helps match
                  volunteers with foster families in order to provide assistance
                  such as meals, baby-sitting, and other help towards the
                  community
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  window.open(
                    "https://github.com/Lambda-School-Labs/foster-together-fe"
                  );
                }}
              >
                Github Repo
              </Button>
              {/* <Button
                size="small"
                color="primary"
                onClick={() => {
                  push("/portfolio/foster-together");
                }}
              >
                Learn More
              </Button> */}
            </CardActions>
          </Card>
        </Grid>
        <Grid className="foster-together" item xs={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="image"
                alt="Secret Family Recipe"
                height="140"
                image={require("../assets/images/secret-family-recipes.png")}
                title="Secret Family Recipe"
                style={styles.media}
                onClick={() => {
                  window.open(
                    "https://github.com/BW-secret-family-recipes1/Front-end"
                  );
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Secret Family Recipe
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Secret Family Recipe was a collaborative project done over the
                  course of 1 week, I was in charge of creating the CRUD
                  functionalities for the application.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  window.open(
                    "https://github.com/BW-secret-family-recipes1/Front-end"
                  );
                }}
              >
                Github Repo
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default PortfolioPage;
