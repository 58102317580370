import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#282c34",
    overflow: "hidden",
    position: "fixed",
    width: "100%",
    top: "0",
    zIndex: "9"
  }
});

const Navigation = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { push } = useHistory();
  return (
    <div className="navigation-wrapper">
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="primary"
          centered
        >
          <Tab
            label="Home"
            style={{ color: "#fff" }}
            onClick={() => {
              push("/");
            }}
          />

          {/* <Tab
            label="Portfolio"
            style={{ color: "#fff" }}
            onClick={() => {
              push("/portfolio");
            }}
          /> */}

          <Tab
            label="About Me"
            style={{ color: "#fff" }}
            onClick={() => {
              push("/about");
            }}
          />

          <Tab
            label="Email Me"
            style={{ color: "#fff" }}
            onClick={() => {
              window.open("mailto:ricardo.leitejr95@gmail.com");
            }}
          />
          <Tab
            label="Resume"
            style={{ color: "#fff" }}
            onClick={() => {
              window.open(
                "https://ricardosresumepdf.s3.us-west-1.amazonaws.com/Ricardo_Leite_Resume.09.06.21.pdf"
              );
            }}
          />
          <div className="icon-grid">
            <a
              href="https://github.com/ricardo-ljr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon style={{ color: "#fff" }} />
            </a>
            <a
              href="https://www.linkedin.com/in/ricardo-leite-jr-651b92115/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <LinkedInIcon style={{ color: "#fff" }} />
            </a>
          </div>
        </Tabs>
      </Paper>
    </div>
  );
};

export default Navigation;
