import React from "react";

const FosterTogether = () => {
  return (
    <div className="foster-page">
      <div className="main-content">
        <h1>I'm foster Together</h1>
      </div>
    </div>
  );
};

export default FosterTogether;
