import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { textAlign } from "@material-ui/system";
import { requirePropFactory } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    marginTop: "50px",
    fontSize: "1.4rem",
    textAlign: "center"
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <img
              className="about-me-img"
              src={require("../assets/images/family-pic.jpg")}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <br />
            I found my passion for coding. <br />
            Ever since I was young, I gravitated towards anything that was
            tech-related. I was amazed by how computers were built, what made
            each gear turn, and specifically how it made it all work. I've also
            always had a passion to understand and solve problems - I love a
            good challenge and I immerse myself striving to solve the problem at
            hand and I don't stop until I'm done. <br />
            <br /> I thoroughly enjoy writing clean code that works, I work
            mainly with a MERN stack - React and Redux are my jam. But I am also
            proficient in C++ and Python - and I'm always open to learn and
            apply new things. My specialties also include Web Development with
            HTML, CSS, JavaScript, jQuery, BootStrap, SASS, LESS. I'm also well
            versed in Git version control and all my main projects are stored in
            GitHub.
            <br /> <br />
            I'm always extremely enthusiastic to learn something new - some on
            my "want to learn" list include but are not limited to AWS Cloud,
            C#, .Net, Docker, and many more... I look forward to seeing what the
            future holds for me and I'm excited to become even more passionate
            about programming as I sharpen my skills and further my career in
            software development.
            <br />
            <br />
            I'm well-prepared to transition-related education and hands-on
            projects to excel in an entry-level software engineer role.{" "}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
