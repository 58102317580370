import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  }
});

function HomePage() {
  const classes = useStyles();

  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
      marginTop: "30"
    }
  };

  return (
    <div className="home-styles">
      <div className="home-title">
        <h1>Hey! I'm Ricardo</h1>
        <h2>
          I'm an enthusiastic <strong>Full-Stack developer</strong> - I love
          problem-solving, building mobile and web applications, and developing
          products for companies that positively impact people's lives!
        </h2>
      </div>

      <div class="separator">Some Of My Latest Work</div>
      <div className="grid-work">
        <Grid container spacing={9} justify="space-evenly">
          <Grid item xs={4}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="image"
                  alt="Contemplative Reptile"
                  height="140"
                  image={require("../assets/images/foster-together-project.png")}
                  title="Contemplative Reptile"
                  style={styles.media}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Foster Together
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Foster Together is a non-profit organization that helps
                    match volunteers with foster families in order to provide
                    assistance such as meals, baby-sitting, and other help
                    towards the community. Foster Together's main goal is to
                    help provide aid to as many foster families as possible
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    window.open("https://app.fostertogether.co/signup");
                  }}
                  size="small"
                  color="primary"
                >
                  Website
                </Button>

                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    window.open(
                      "https://github.com/Lambda-School-Labs/foster-together-fe"
                    );
                  }}
                >
                  Github Repo
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="image"
                  alt="Secret Family Recipe"
                  height="140"
                  image={require("../assets/images/secret-family-recipes.png")}
                  title="Secret Family Recipe"
                  style={styles.media}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Secret Family Recipe
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Secret Family Recipe was a collaborative project done over
                    the course of 1 week, I was in charge of creating the CRUD
                    functionalities for the application, in which a user can
                    create secret recipes and share with their family. Users can
                    also search across recipes from family members
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    window.open(
                      "https://secret-family-recipes.firebaseapp.com/"
                    );
                  }}
                  size="small"
                  color="primary"
                >
                  Website
                </Button>

                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    window.open(
                      "https://github.com/Lambda-School-Labs/foster-together-fe"
                    );
                  }}
                >
                  Github Repo
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="image"
                  alt="Contemplative Reptile"
                  height="140"
                  image={require("../assets/images/sorting-visualizer.png")}
                  title="Contemplative Reptile"
                  style={styles.media}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Sorting Visualizer
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    I built this project utilizing VueJS, Vue-Material and SASS
                    - I've always wanted to create some sort of algorithm
                    visualization in order to learn algorithms more profoundly
                    and be able to showcase what I mean by "algorithms" to my
                    friends and family!
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    window.open("http://visualizer.ricardol.com/");
                  }}
                  size="small"
                  color="primary"
                >
                  Website
                </Button>

                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    window.open(
                      "https://github.com/ricardo-ljr/Sorting-Visualizer"
                    );
                  }}
                >
                  Github Repo
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={9} justify="space-evenly">
          <Grid item xs={4}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="image"
                  alt="Contemplative Reptile"
                  height="140"
                  image={require("../assets/images/front-end-mentor-challenge.png")}
                  title="Contemplative Reptile"
                  style={styles.media}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Where in The World
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    I saw this challenge in Frontend Mentor and wanted to give
                    it a try. This project was built using a REST API of
                    countries. I used ReactJS in order to built it's
                    functionalities, namely HTTP request, components, color
                    theme switcher and routes.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    window.open("https://world-directory-fa57b.web.app/");
                  }}
                  size="small"
                  color="primary"
                >
                  Website
                </Button>

                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    window.open(
                      "https://github.com/ricardo-ljr/Where-In-The-World"
                    );
                  }}
                >
                  Github Repo
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default HomePage;
