import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./components/home";
import Portfolio from "./components/portfolio";
import About from "./components/aboutMe";
import Navigation from "./components/navigation";
import FosterTogether from "./components/portfolio-pieces/fosterTogether";

// Background Particles
import ParticlesBg from "particles-bg";
// Material UI Imports
// Transition Imports
import { CSSTransition, TransitionGroup } from "react-transition-group";

import "./App.css";

function App() {
  return (
    <>
      <Navigation />

      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={450} classNames="fade">
              <Switch location={location}>
                <div className="App-header">
                  <Route exact path="/" component={Home} />
                  <Route exact path="/portfolio" component={Portfolio} />
                  <Route exact path="/about" component={About} />
                  <Route
                    exact
                    path="/portfolio/foster-together"
                    component={FosterTogether}
                  />
                </div>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
      <ParticlesBg color="#5c5c5c" type="cobweb" bg={true} />
    </>
  );
}

export default App;
